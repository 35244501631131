<template>
  <div class="flix-container" v-if="steps">
    <progressBar :percent="Math.round((currentStep / (steps.length - 1) ) * 100)" />
    <transition name="flixFadeIn">
      <component :key="currentStep" :data="data" :is="steps[currentStep].component" :callback="function(data) { setData(data) }" />
    </transition>
    <ol class="flix-breadcrumb">
      <breadcrumb v-for="(step, index) in steps" :key="index+currentStep" :show="[currentStep, index]" :callback="function(id){currentStep = id}">
        <template v-slot:badge><span v-if="!step.check">{{ index + 1 }}</span></template>
        <template v-slot:title>{{ step.title }}</template>
      </breadcrumb>
    </ol>
  </div>
</template>
<script>
export default {
  components: {
    progressBar () { return import('@/components/assistent/components/assets/progressbar') },
    breadcrumb () { return import('@/components/assistent/components/assets/breadcrumb') },
    calendarFormat () { return import('@/components/assistentPrivate/components/calendarFormat') },
    calendarTitle () { return import('@/components/assistentPrivate/components/timeRange') },
    calendarAppointments () { return import('@/components/assistent/components/appointments') },
    calendarSettings () { return import('@/components/assistentPrivate/components/settings') },
    calendarPreview () { return import('@/components/assistentPrivate/components/preview') }
  },
  props: {
    settings: Object
  },
  data () {
    return {
      data: this.getData(),
      steps: false,
      currentStep: this.getCurrentStep()
    }
  },
  watch: {
    '$i18n.locale' () { this.getSteps() }
  },
  methods: {
    getSteps () {
      this.steps = [
        { title: this.$t('message.calendarFormat'), check: false, component: 'calendarFormat' },
        { title: this.$t('message.timeRange'), check: false, component: 'calendarTitle' },
        { title: this.$tc('message.appointmentRequests', 2), check: false, component: 'calendarAppointments' },
        { title: this.$t('message.additionalSettings'), check: false, component: 'calendarSettings' },
        { title: this.$t('message.preview'), check: false, component: 'calendarPreview' }
      ]
    },
    getCurrentStep () {
      if (typeof this.settings === 'object' && typeof this.settings.data === 'object') {
        return 4
      }
      return 0
    },
    getData () {
      if (typeof this.settings === 'object' && typeof this.settings.data === 'object') {
        return this.settings.data
      }
      return {
        ID: new Date().getTime() + '' + Math.floor(Math.random(0, 1) * 10000),
        type: 'eventpicker',
        email: '',
        password: '',
        title: '',
        comments: 1,
        location: '',
        exclude: {
          anonym: 0
        },
        max_applicants: [20],
        form: 'privateEvent'
      }
    },
    setData (data) {
      this.data = Object.assign(this.data, data)
      this.currentStep++
      // this.settings.onChange()
    }
  },
  mounted () {
    this.getSteps()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
